import React from 'react';
import './WorkOverview.css';
import image1 from '../images/IMG_1392.JPEG'
import image2 from '../images/IMG_5169.JPEG'
import image3 from '../images/Garden 2.jpg'
import image4 from '../images/IMG_1386.JPEG'

const WorkOverview = () => {
  const works = [
    { title: 'Kitchens', description: 'Designed, sourced and installed.', img: image1 },
    { title: 'Bathrooms', description: 'Plumbing, white goods and full plumbing serivces.', img: image2 },
    { title: 'Garden structures', description: 'Designed, managed end-to-end, and installed to high specification', img: image3 },
    { title: 'Open plan conversion', description: 'From engineering specifications, to fully finshed aperture.', img: image4 },
  ];

  return (
    <div className="work-overview" id="work-overview">
      <h2>Key Development Services</h2>
      <div className="cards">
        {works.map((work, index) => (
          <div className="card" key={index}>
            <img src={work.img} alt={work.title} />
            <h3>{work.title}</h3>
            <p>{work.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default WorkOverview;
