import React from 'react';
import './ImportantWork.css';
import image1 from '../images/IMG_1200.JPEG'
import image2 from '../images/planning.jpg'
import image3 from '../images/IMG_1392.JPEG'
import image4 from '../images/commerical.jpg'
import image5 from '../images/Garden 1.jpg'

const ImportantWork = () => {
  return (
    <div className="important-work" id="important-work">
      <div className='important-work-wrapper'>

        <div className='important-work-item'>
          <img src={image2} alt="Important work" />
          <div className="work-description">
            <h2>End-to-end project management</h2>
            <p>With extensive experience managing a wide range of projects from multi million pound housing developments to residential and commercial refurbishments, we pride ourselves in our ability to make sure each and every project runs smoothly, on time and to the highest of standards. 
              You will have a single point of contact throughout your project who while guide you through the process of being your project to life. 
            </p>
          </div>
        </div>

        <div className='important-work-item'>
          <img src={image1} alt="Important work" />
          <div className="work-description">
            <h2>New build and extensions</h2>
            <p>Whether you are looking to build your dream home or develop your existing one our dedicated team are able to bring your plans to successful completion. 
              Extensions to existing properties are an excellent way to add square footage and in turn value to your property without the stress and the costs of the moving process. </p>
            <button className='important-work-button'>Contact us about extensions</button>
          </div>
        </div>

        <div className='important-work-item'>
          <img src={image4} alt="Important work" />
          <div className="work-description">
            <h2>Commercial refurbishments and maintenance</h2>
            <p>With experience working on everything from fast paced shopfitting projects to public sector maintenance, whether it’s routine maintenance or a large scale refurbishment we are able to help. We understand that working in the commercial world often requires a quick turn around on project whilst meeting high standards of detail. </p>
            <button className='important-work-button'>Contact us about commercial</button>
          </div>
        </div>

        <div className='important-work-item'>
          <img src={image3} alt="Important work" />
          <div className="work-description">
            <h2>Residential refurbishment and remodelling</h2>
            <p>Our team are able to cater to a wide range of work from high end, full refurbishments to open plan knock through’s. You will have a single point of contact throughout the duration of the project. </p>
            <button className='important-work-button'>Contact us about refurbishment</button>
          </div>
        </div>

        <div className='important-work-item'>
          <img src={image5} alt="Important work" />
          <div className="work-description">
            <h2>Gardens structures and landscaping</h2>
            <p>Your outside space is just as important as the inside and we believe a well designed garden will change the way you use your home.</p>
            <p>Whether you love entertaining or want to add space to enjoy with your family garden rooms, pergolas or treehouses are the perfect fit and can be tailed to suit any garden. Are team are able to design and build your project and give you a reason to get outside and enjoy your garden.</p>
          </div>
        </div>

      </div>
    </div>
  );
};

export default ImportantWork;
